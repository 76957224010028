import React, { SVGAttributes } from 'react';

const Pinterest = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 24" {...props}>
        <defs />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M10.052 0c-3.846 0-6.21 1.565-7.518 2.878C.924 4.495 0 6.644 0 8.77c0 2.672 1.126 4.723 3.01 5.485.127.052.254.078.38.078.397 0 .712-.259.821-.673.064-.238.211-.824.275-1.079.137-.502.027-.744-.273-1.094-.545-.64-.799-1.398-.799-2.384 0-2.927 2.196-6.038 6.265-6.038 3.229 0 5.235 1.822 5.235 4.755 0 1.85-.402 3.564-1.131 4.826-.507.877-1.398 1.922-2.766 1.922-.592 0-1.123-.242-1.459-.662-.316-.398-.42-.912-.293-1.447.144-.605.34-1.236.53-1.846.346-1.114.674-2.166.674-3.006 0-1.435-.89-2.4-2.213-2.4-1.681 0-2.999 1.695-2.999 3.86 0 1.062.284 1.856.413 2.16-.212.892-1.47 6.19-1.71 7.189-.138.583-.97 5.189.407 5.556 1.547.413 2.93-4.073 3.07-4.58.115-.412.514-1.971.759-2.93.746.715 1.949 1.197 3.119 1.197 2.205 0 4.189-.985 5.585-2.774 1.354-1.735 2.1-4.154 2.1-6.81 0-2.077-.898-4.124-2.464-5.617C14.872.873 12.569 0 10.052 0z"
        />
    </svg>
);
export default Pinterest;
