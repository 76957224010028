import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import styled from 'styled-components';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import React, { FC, useState } from 'react';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Page from '@oberoninternal/travelbase-website/dist/components/Page';
import { Flex, Box } from '@rebass/grid';
import { Form, Formik } from 'formik';
import { TextInputField } from '@oberoninternal/travelbase-ds/components/form/TextInput';
import * as Yup from 'yup';
import subscribeToNewsletter from '../utils/subscribeToNewsletter';
import TertiaryButton from './TertiaryButton';

const messages = defineMessages({
    emailPlaceholder: {
        defaultMessage: 'Vul hier je e-mailadres in',
    },
});

const validationSchema = Yup.object().shape({
    newsletterEmail: Yup.string().email().required(),
});

const NewsletterSignup = () => {
    const { formatMessage, locale } = useIntl();
    const [subscribed, setSubscribed] = useState(false);

    return (
        <NewsletterSignupWrapper>
            <StyledContentWrapper>
                <Container py={[7, 8]}>
                    <Formik
                        validationSchema={validationSchema}
                        onSubmit={values => {
                            subscribeToNewsletter(values.newsletterEmail, locale);
                            setSubscribed(true);
                        }}
                        initialValues={{ newsletterEmail: '' }}
                    >
                        {({ isValid }) =>
                            !subscribed ? (
                                <>
                                    <Box mb={[0, 3]}>
                                        <Title variant="large">
                                            <FormattedMessage defaultMessage="Meld je aan" />
                                        </Title>
                                    </Box>

                                    <Box mb={[6, 5]}>
                                        <StyledBody>
                                            <FormattedMessage defaultMessage="Voor een wekelijkse dosis inspiratie, voorpret én natuurlijk de beste acties en aanbiedingen, meld je je aan voor onze nieuwsbrief." />
                                        </StyledBody>
                                    </Box>
                                    <Form autoComplete="off">
                                        <StyledFlex mx={[0, -3, -4]}>
                                            <Box width={[1 / 1, 1 / 1, 2 / 4]} mb={[4, 3]} px={[0, 3, 4]}>
                                                <StyledTextInputField
                                                    type="email"
                                                    name="newsletterEmail"
                                                    placeholder={formatMessage(messages.emailPlaceholder)}
                                                    hideErrorMessage
                                                />
                                            </Box>
                                            <Box width={[1 / 1, 1 / 1, 1 / 4]} mb={[4, 3]} px={[0, 3, 4]}>
                                                <StyledButton size="large" disabled={!isValid} type="submit">
                                                    <FormattedMessage defaultMessage="Inschrijven" />
                                                </StyledButton>
                                            </Box>
                                        </StyledFlex>
                                    </Form>
                                </>
                            ) : (
                                <Body>
                                    <FormattedMessage defaultMessage="Je hebt je ingeschreven voor de nieuwsbrief." />
                                </Body>
                            )
                        }
                    </Formik>
                </Container>
            </StyledContentWrapper>
        </NewsletterSignupWrapper>
    );
};

const StyledFlex = styled(Flex)`
    flex-direction: column;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-direction: row;
    }
`;
const StyledButton = styled(TertiaryButton)`
    width: 100%;
    height: 4.8rem;
    border-radius: 0.8rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        max-width: 20rem;
    }
`;
const StyledTextInputField = styled(TextInputField)`
    height: 4.8rem;
`;

const Container = styled(Box)`
    display: flex;
    flex: 1;
    flex-direction: column;
    color: ${({ theme }) => theme.colors.neutral[0]};
    justify-content: center;
    position: relative;
    z-index: 2;

    h1 {
        color: inherit;
        font-weight: 800;
    }
`;

const StyledContentWrapper = styled(ContentWrapper as FC<React.PropsWithChildren<unknown>>)`
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;

    :before {
        position: absolute;
        content: '';
        display: block;
        background-image: none;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top right;
        width: 64%;
        top: 32%;
        bottom: 0;
        right: -3.2rem;
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            background-position: top center;
            width: calc(30% - 2.4rem);
            height: 100%;
            top: 0;
            bottom: 0;
            right: var(--wrapperPadding);
        }
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
            background-image: url('/static/img/beach.png');
            right: var(--wrapperPadding);
        }
        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xxl}) {
            min-width: 37.8rem;
        }
    }
`;

const StyledBody = styled(Body)`
    max-width: 32rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        max-width: none;
    }
`;

const NewsletterSignupWrapper = styled(Page as FC<React.PropsWithChildren<unknown>>)`
    height: 40rem;
    background: ${({ theme }) => theme.colors.primary[70]};
`;

export default NewsletterSignup;
