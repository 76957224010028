import React, { FC } from 'react';
import styled from 'styled-components';

interface Props {
    rounded?: boolean;
    icon: React.ReactNode;
}

const Icon: FC<React.PropsWithChildren<Props>> = ({ icon, rounded, ...rest }) => (
    <IconWrapper rounded={rounded} {...rest}>
        {icon}
    </IconWrapper>
);

export default Icon;

const IconWrapper = styled.div<{ rounded?: boolean }>`
    height: 4rem;
    width: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ rounded, theme }) =>
        rounded &&
        `
    border-radius: 50%;
    border: .1rem solid ${theme.tokens?.icon.border};
    `}

    svg {
        height: 1.6rem;
        width: 1.6rem;
        color: ${({ theme }) => theme.tokens?.icon.svg.color};
    }

    &:hover {
        background-color: ${({ theme }) => theme.tokens?.icon.hover.backgroundColor};
        border: 0.1rem solid ${({ theme }) => theme.tokens?.icon.hover.border};

        svg {
            color: ${({ theme }) => theme.tokens?.icon.hover.svg};
        }
    }
`;
