import React, { SVGAttributes } from 'react';

const Twitter = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 24" {...props}>
        <defs />
        <path
            fill="currentColor"
            fillRule="nonzero"
            d="M26.939 6.768c0-.26 0-.52-.012-.78A12.43 12.43 0 0030 2.846a12.636 12.636 0 01-3.541.957A6.112 6.112 0 0029.172.45a12.368 12.368 0 01-3.914 1.464A6.192 6.192 0 0020.768 0c-3.397 0-6.158 2.717-6.158 6.06 0 .471.06.932.156 1.38a17.577 17.577 0 01-12.69-6.33 5.983 5.983 0 00-.828 3.047c0 2.103 1.093 3.957 2.738 5.044A6.305 6.305 0 011.2 8.445v.083c0 2.929 2.125 5.385 4.934 5.94a6.094 6.094 0 01-1.62.213c-.396 0-.78-.035-1.153-.106.78 2.41 3.062 4.157 5.75 4.205a12.476 12.476 0 01-7.646 2.598c-.493 0-.985-.024-1.465-.083A17.72 17.72 0 009.424 24c11.32 0 17.515-9.236 17.515-17.232z"
        />
    </svg>
);
export default Twitter;
