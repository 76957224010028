import styled from 'styled-components';
import cssCalcBetweenViewport from '../../utils/cssCalcBetweenViewport';

const NavWrapper = styled.div`
    margin: 0;
    margin-right: ${cssCalcBetweenViewport(20, 40)};
    margin-left: ${cssCalcBetweenViewport(20, 40)};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        margin-left: calc(${({ theme }) => theme.tokens?.header?.logoOffsetLeft} + ${cssCalcBetweenViewport(20, 40)});
    }
`;

export default NavWrapper;
