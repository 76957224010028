// https://google-webfonts-helper.herokuapp.com/fonts/open-sans-condensed?subsets=latin
// https://google-webfonts-helper.herokuapp.com/fonts/open-sans?subsets=latin

export default `
/* open-sans-condensed-300 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('/static/fonts/open-sans-condensed-v15-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/static/fonts/open-sans-condensed-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/open-sans-condensed-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/open-sans-condensed-v15-latin-300.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/open-sans-condensed-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/open-sans-condensed-v15-latin-300.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}
/* open-sans-condensed-700 - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/open-sans-condensed-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/static/fonts/open-sans-condensed-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/open-sans-condensed-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/open-sans-condensed-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/open-sans-condensed-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/open-sans-condensed-v15-latin-700.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}
/* open-sans-condensed-300italic - latin */
@font-face {
  font-family: 'Open Sans Condensed';
  font-style: italic;
  font-weight: 300;
  src: url('/static/fonts/open-sans-condensed-v15-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/static/fonts/open-sans-condensed-v15-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/open-sans-condensed-v15-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/open-sans-condensed-v15-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/open-sans-condensed-v15-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/open-sans-condensed-v15-latin-300italic.svg#OpenSansCondensed') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/static/fonts/open-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/static/fonts/open-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/open-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/open-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/open-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/open-sans-v27-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/static/fonts/OpenSans-ExtraBold.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/static/fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/OpenSans-ExtraBold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/OpenSans-ExtraBold.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/OpenSans-ExtraBold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/OpenSans-ExtraBold.svg#OpenSans') format('svg'); /* Legacy iOS */

}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/static/fonts/open-sans-v27-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/static/fonts/open-sans-v27-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/open-sans-v27-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/open-sans-v27-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/open-sans-v27-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/open-sans-v27-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/static/fonts/open-sans-v27-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/static/fonts/open-sans-v27-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/open-sans-v27-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/open-sans-v27-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/open-sans-v27-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/open-sans-v27-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/static/fonts/open-sans-v27-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('/static/fonts/open-sans-v27-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/fonts/open-sans-v27-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('/static/fonts/open-sans-v27-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('/static/fonts/open-sans-v27-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/static/fonts/open-sans-v27-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
`;
