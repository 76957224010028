import { BrandConfig } from '@oberoninternal/travelbase-website/dist/constants/brandConfig';
import { FormattedMessage } from 'react-intl';
import {
    PaymentMethodEnum,
    SearchRentalUnitsOrderEnum,
    UnitSearchOrderEnum,
} from '@oberoninternal/travelbase-website/dist/generated/graphql';
import React from 'react';
import theme from './theme';
import { rentalUnitTypes } from '@oberoninternal/travelbase-website/dist/constants/filterTypes';
import fonts from './fonts';
import Whatsapp from '@oberoninternal/travelbase-website/dist/components/svg/socials/Whatsapp.svg';
import Mail from '../components/icons/social/Mail';
import Phone from '../components/icons/social/Phone';

const isProd = process.env.NODE_ENV === 'production';
const terschellingConfig: BrandConfig = {
    name: 'Terschelling',
    theme,
    gtmID: 'GTM-K2CFQBW',
    font: {
        css: fonts,
    },
    searchUri: {
        nl: '/accommodaties',
        en: '/unterkuenfte',
        de: '/accommodations',
    },
    locationDescription: {
        imgSrc: '/static/img/drenkelingenhuisje.jpeg',
        imgAlt: {
            nl: 'Het Drenkelingenhuisje',
            en: 'The Drenkelingenhuisje',
            de: 'Das Drenkelingenhuisje',
        },

        tips: {
            nl: [
                'Sterrenkijken in het Dark Sky Park',
                'Bezoek het Drenkelingenhuisje',
                'Fiets langs alle uitzichtpunten',
                'Wadlopen over de bodem van de zee',
                'Een uitgebreide strandwandelling',
            ],
            en: [
                'Stargazing in the Dark Sky Park',
                'Visit the Drenkelingenhuisje',
                'Cycle along all viewpoints',
                'Mudflat walking over the bottom of the sea',
                'An extensive beach walk',
            ],
            de: [
                'Sternenbeobachtung im Dark Sky Park',
                'Besuchen Sie das Drenkelingenhuisje',
                'Radeln Sie entlang aller Aussichtspunkte',
                'Wattwandern über dem Meeresgrund',
                'Ein ausgiebiger Strandspaziergang',
            ],
        },

        description: {
            nl: 'Beleef het ware eilandgevoel.<br/>Waar je ook bent, je proeft, ziet, hoort, ruikt en voelt de zee. Even helemaal weg en dat pure eilandgevoel beleven. Dat is Terschelling. Je hoofd leeg laten waaien en plek maken voor nieuwe herinneringen. Je helemaal onderdompelen in dat ware eilandgevoel.',
            en: 'Experience the true island feeling.<br/>Wherever you are, you can taste, see, hear, smell and feel the sea. Leave your troubles behind and experience that pure island feeling. That is Terschelling. Clear your head and make room for new memories. Completely immerse yourself in that true island feeling.',
            de: 'Erleben Sie das wahre Inselgefühl.<br/>Wo immer Sie sind, Sie können das Meer schmecken, sehen, hören, riechen und fühlen. Entfliehen Sie dem Alltag und erleben Sie das wahre Inselgefühl. Das ist Terschelling. Den Kopf frei bekommen und Platz für neue Erinnerungen schaffen. Tauchen Sie ganz in das wahre Inselgefühl ein.',
        },
    },
    contact: {
        info: [
            {
                name: <FormattedMessage defaultMessage="Stel je vraag via WhatsApp" />,
                content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                icon: <Whatsapp width={24} height={24} />,
                link: 'https://wa.me/+31682640043',
                queryParams: {
                    nl: {
                        text: 'Beste VVV Terschelling, ik heb een vraag over {rentalUnitName}',
                    },
                    de: {
                        text: 'Sehr geehrter Mitarbeiter des VVV Terschelling, ich habe eine Frage zu {rentalUnitName}',
                    },
                    en: {
                        text: 'Dear VVV Terschelling employee, I have a question about {rentalUnitName}',
                    },
                },
            },
            {
                name: (
                    <>
                        <FormattedMessage defaultMessage="Bel" /> <span>+31 562 443000</span>
                    </>
                ),
                content: <FormattedMessage defaultMessage="ma. t/m za. tussen 9.00 en 17.00 uur." />,
                icon: <Phone width={24} height={24} />,
                link: 'tel:+31562443000',
            },

            {
                name: <FormattedMessage defaultMessage="E-mail" />,
                content: <FormattedMessage defaultMessage="Je krijgt binnen twee werkdagen een reactie." />,
                icon: <Mail width={24} height={24} />,
                link: 'mailto:info@vvvterschelling.nl',
                queryParams: {
                    nl: {
                        body: 'Beste VVV Terschelling, ik heb een vraag over {rentalUnitName}',
                    },
                    de: {
                        body: 'Sehr geehrter Mitarbeiter des VVV Terschelling, ich habe eine Frage zu {rentalUnitName}',
                    },
                    en: {
                        body: 'Dear VVV Terschelling employee, I have a question about {rentalUnitName}',
                    },
                },
            },
        ],
        page: {
            nl: 'https://www.vvvterschelling.nl/contact/',
            en: 'https://www.vvvterschelling.de/kontakt/',
            de: 'https://www.vvvterschelling.com/contact/',
        },
    },
    customSlugs: {
        agenda: {
            nl: 'excursies',
            de: 'ausfluge',
            en: 'excursions',
        },
        activity: {
            nl: 'excursies',
            de: 'ausfluge',
            en: 'excursions',
        },
        company: {
            nl: 'bedrijven',
            de: 'firmen',
            en: 'companies',
        },
        accommodation: {
            nl: 'accommodaties',
            de: 'unterkuenfte',
            en: 'accommodations',
        },
    },
    // Map zoom level
    mapZoom: 13,

    // Map min zoom level
    mapMinZoom: {
        desktop: 10.5,
        mobile: 8.5,
    },

    locales: ['nl', 'en', 'de'],
    accommodationTypes: [
        {
            // not a valid filter, so make sure to escape the value when using it
            message: 'all',
            value: 'all',
        },
        {
            message: 'vacationHomes',
            value: rentalUnitTypes.HOME,
        },
        {
            message: 'chalet',
            value: 'type-of-accommodation.chalet',
        },
        {
            message: 'apartment',
            value: 'type-of-accommodation.appartment',
        },
        {
            message: 'hotelOrBnB',
            value: rentalUnitTypes.ROOM,
        },
        {
            message: 'camping',
            value: rentalUnitTypes.CAMPING_PITCH,
        },
        {
            message: 'camperPitch',
            value: 'general.camper-welcome',
        },
        {
            message: 'tent',
            value: rentalUnitTypes.TENT,
        },
    ],
    groupTypes: ['adults', 'youth', 'children', 'babies', 'pets'],
    unitSearchOrder: {
        popularity: SearchRentalUnitsOrderEnum.RecentBookingsBoosted,
        ratingDesc: SearchRentalUnitsOrderEnum.ReviewRatingDesc,
    },
    tripsSearchOrder: {
        popularity: UnitSearchOrderEnum.RecentBookingsBoosted,
        ratingDesc: UnitSearchOrderEnum.ReviewRatingDesc,
        priceAsc: UnitSearchOrderEnum.PriceAsc,
        priceDesc: UnitSearchOrderEnum.PriceDesc,
    },
    searchBar: { hide: true },
    coordinates: {
        latitude: 53.3970125,
        longitude: 5.3348613,
    },

    seo: {
        twitterUsername: '@terschellingvvv',
        siteName: 'VVV Terschelling',
    },
    termsAndConditions: {
        nl: `${isProd ? 'https://' : 'http://'}${
            process.env.NEXT_PUBLIC_DOMAIN_NL
        }/algemene-reisvoorwaarden-vvv-terschelling/`,
        en: `${isProd ? 'https://' : 'http://'}${process.env.NEXT_PUBLIC_DOMAIN_EN}/general-terms-and-conditions/`,
        de: `${isProd ? 'https://' : 'http://'}${process.env.NEXT_PUBLIC_DOMAIN_DE}/allgemeine-reisebedingungen/`,
    },

    minBookingAge: 18,
    cancellationInsuranceDefault: false,
    bookButtonVariant: 'primary',
    surveyUrl: {
        nl: '',
        en: '',
        de: '',
    },
    checkout: {
        mobileHeaderPlacement: 'bottom',
        initialPaymentMethod: {
            nl: PaymentMethodEnum.Ideal,
            en: PaymentMethodEnum.CreditCard,
            de: PaymentMethodEnum.Paypal,
        },
        insuranceTerms: {
            nl: '/static/waarborgfonds_polisvoorwaarden.pdf',
            en: '/static/waarborgfonds_polisvoorwaarden.pdf',
            de: '/static/waarborgfonds_polisvoorwaarden.pdf',
        },
        showBookingConfirmExtras: true,
    },
    complaintFormUrl: {
        nl: 'mailto:info@vvvterschelling.nl',
        en: 'mailto:info@vvvterschelling.nl',
        de: 'mailto:info@vvvterschelling.nl',
    },
    unitExras: {
        svgFilter:
            'brightness(0) saturate(100%) invert(18%) sepia(25%) saturate(1901%) hue-rotate(190deg) brightness(93%) contrast(93%)',
    },
    navigation: {
        offset: 27.4,
    },
    enableFavorites: true,
};

export default terschellingConfig;
