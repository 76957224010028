import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';
import styled from 'styled-components';
import { ColorAssignment } from '@oberoninternal/travelbase-ds/themes/default';
import { ComponentProps, FC } from 'react';
// eslint-disable-next-line import/no-named-default
import { default as defaultTheme } from '../constants/theme';

const assignment: ColorAssignment = defaultTheme.colorAssignments.main;

const TertiaryButton = styled(Button)`
    font-family: ${({ theme }) => theme.fontFamily.title};
    background: ${({ theme }) => getAssignmentColor(theme, assignment)};
    --border-color: ${({ theme }) => getAssignmentColor(theme, assignment)};

    :active,
    :hover,
    :focus {
        --border-color: ${({ theme }) => getAssignmentColor(theme, assignment, 1)};
    }

    :active {
        background: ${({ theme }) => getAssignmentColor(theme, assignment, 1)};
        --border-color: ${({ theme }) => getAssignmentColor(theme, assignment, 1)};
    }
`;

export default TertiaryButton as FC<React.PropsWithChildren<ComponentProps<typeof Button>>>;
