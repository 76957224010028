import defaultTheme, { Spacing, Colors } from '@oberoninternal/travelbase-ds/themes/default';
import { TitleVariant } from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { BodyVariant } from '@oberoninternal/travelbase-ds/components/primitive/Body';
import { FontStyle } from '@oberoninternal/travelbase-ds/constants/theme';
import ExtendedTheme from './ExtendedTheme';

const colors: Colors = {
    primary: {
        0: '#F5F8FA',
        5: '#DFF1FB',
        10: '#C9E7F7',
        20: '#A3D5EE',
        30: '#80C1E1',
        40: '#62AAD1', // Helderblauw
        50: '#4F8FC0',
        60: '#0B63B9',
        70: '#0A59A6',
        80: '#223260', // "Blauw"
        90: '#141940',
        100: '#070717',
    },
    secondary: {
        0: '#FFF8FA',
        5: '#FFE9F1',
        10: '#FFDAE8',
        20: '#FFC4DA',
        30: '#FF9DC1',
        40: '#FF69A1',
        50: '#FF4087',
        60: '#FF126A',
        70: '#E30054',
        80: '#CC004C',
        90: '#B50043',
        100: '#9E003B',
    },
    tertiary: {
        0: '#FFFCF7',
        5: '#FDF2DF',
        10: '#FCE9C7',
        20: '#FAE0AF',
        30: '#F8CD7E',
        40: '#F5BA4E',
        50: '#F2A71E',
        60: '#CC880C',
        70: '#976509',
        80: '#7D5307',
        90: '#624106',
        100: '#483004',
    },
    neutral: {
        0: '#ffffff',
        5: '#fbfcfc',
        10: '#f7f8f9',
        20: '#e6e9eb',
        30: '#cbd0d4',
        40: '#a4acb2',
        50: '#767f87',
        60: '#48515b',
        70: '#212a36',
        80: '#060e1c',
        90: '#00040d',
        100: '#000208',
    },
    positive: {
        0: '#ebffeb',
        5: '#E2FDE3',
        10: '#d9fbdb',
        20: '#b3f0b5',
        30: '#7ade7e',
        40: '#36c43c',
        50: '#00a308',
        60: '#008207',
        70: '#006606',
        80: '#005206',
        90: '#004705',
        100: '#004206',
    },
    warning: {
        0: '#ffe8be',
        5: '#fed790',
        10: '#fec662',
        20: '#f9ae2c',
        30: '#f1990b',
        40: '#e48800',
        50: '#d37800',
        60: '#be6500',
        70: '#a65000',
        80: '#8c3d00',
        90: '#722c00',
        100: '#591e00',
    },
    negative: {
        0: '#fff1f1',
        5: '#FEEBEB',
        10: '#fde6e6',
        20: '#f8cecd',
        30: '#f2a8a7',
        40: '#e87975',
        50: '#dc463e',
        60: '#cc1a0d',
        70: '#b71100',
        80: '#9f1400',
        90: '#831400',
        100: '#661300',
    },
};

const spacing: Spacing = {
    '00_None': '0px',
    '10_Micro': '4px',
    '20_Tiny': '8px',
    '30_Small': '12px',
    '40_Standard': '16px',
    '50_Semi': '24px',
    '60_Large': '32px',
    '70_XLarge': '40px',
    '80_XXLarge': '56px',
};

const baseTitle: Partial<FontStyle> = {
    color: colors.primary[80],
};

const titleStyles: Record<TitleVariant, FontStyle> = {
    large: {
        size: { min: 29, max: 39 },
        line: { min: 36, max: 40 },
        weight: 800,
        letterSpacing: 0,
        ...baseTitle,
    },
    regular: {
        size: { min: 31, max: 31 },
        line: { min: 32, max: 32 },
        weight: 800,
        letterSpacing: 0,
        ...baseTitle,
    },
    small: {
        size: { min: 19, max: 20 },
        line: { min: 24, max: 24 },
        ...baseTitle,
    },
    tiny: {
        size: { min: 15, max: 16 },
        line: { min: 20, max: 20 },
        ...baseTitle,
    },
    micro: {
        size: { min: 15, max: 16 },
        line: { min: 15, max: 20 },
        ...baseTitle,
    },
};

const bodyStyles: Record<BodyVariant, FontStyle> = {
    huge: {
        size: { min: 15, max: 21 },
        line: { min: 28, max: 32 },
        weight: 200,
    },
    large: {
        size: { min: 18, max: 19 },
        line: { min: 24, max: 28 },
        weight: 300,
    },
    regular: {
        size: { min: 15, max: 17 },
        line: { min: 24, max: 28 },
        letterSpacing: 0,
        weight: 400,
    },
    small: {
        size: { min: 15, max: 16 },
        line: { min: 24, max: 24 },
        letterSpacing: 0,
        weight: 400,
    },
    tiny: {
        size: { min: 14, max: 15 },
        line: { min: 16, max: 16 },
        letterSpacing: 0,
        weight: 600,
    },
    micro: {
        size: { min: 12, max: 12 },
        line: { min: 16, max: 16 },
        letterSpacing: 0,
    },
};

const mediaQueriesValues = {
    xs: 480,
    s: 688,
    m: 1024,
    l: 1200,
    xl: 1520,
    xxl: 1660,
    xxxl: 1920,
};

// Should be of type 'Theme' from package, but that has to be updated
const theme: ExtendedTheme = {
    ...defaultTheme,
    name: 'Terschelling',

    fontFamily: {
        title: 'Open Sans Condensed, sans-serif',
        body: 'Open Sans, sans-serif',
    },
    fontSizes: {
        title: titleStyles,
        body: bodyStyles,
    },
    breakpoints: Object.values(mediaQueriesValues).map(value => `${value}px`),
    mediaQueries: Object.assign(
        {},
        ...Object.entries(mediaQueriesValues).map(([key, value]) => ({
            [key]: `${value}px`,
        }))
    ),
    space: Object.values(spacing),
    spacing,
    mediaQueriesValues,
    colors,
    colorAssignments: {
        main: ['secondary', 50],
        buttonLight: ['primary', 60],
        buttonPrimary: ['secondary', 50],
        textAction: ['primary', 60],
        input: ['secondary', 50],
        form: ['primary', 50],
        rating: ['primary', 60],
        booking: ['secondary', 50],
        special: ['primary', 40],
        searchBox: {
            backgroundColor: ['primary', 70],
            color: ['neutral', 0],
        },
        tagButton: {
            color: ['neutral', 0],
            backgroundColor: ['secondary', 50],
            borderColor: ['secondary', 60],
        },
        calendar: defaultTheme.colorAssignments.calendar,
    },
    radius: {
        button: '0.8rem',
        textInput: '0.8rem',
        textArea: '0.8rem',
        dropdown: '0.5rem',
    },
    rounded: false,
    tokens: {
        header: {
            logoOffsetLeft: '28.3rem',
        },
        footer: {
            wrapper: {
                backgroundColor: colors.neutral['0'],
            },
            color: colors.primary['80'],
            divider: {
                color: colors.neutral['20'],
                margin: 'var(--paddingLeft)',
            },
        },
        icon: {
            border: colors.neutral['30'],
            svg: {
                color: colors.primary['80'],
            },
            hover: {
                backgroundColor: colors.primary['80'],
                border: colors.primary['80'],
                svg: colors.neutral['0'],
            },
        },
        highlights: {
            gridGap: '1.2rem',
        },
    },
};

export default theme;

// Experimental code to implement design tokens
/*
export const designTokens = {
    icon: {
        border: theme.colors.primary['80'],
        color: theme.colors.neutral['0'],
        hover: {
            backgroundColor: theme.colors.neutral['0'],
            color: theme.colors.primary['60']
        }
    }
}
*/
