import React, { FC, ReactNode, useCallback } from 'react';
import FlagEN from './icons/FlagEN';
import FlagDE from './icons/FlagDE';
import FlagNL from './icons/FlagNL';
import { useRouter } from 'next/router';
import Caret from './icons/Caret';
import { OptionType, SelectInputField } from '@oberoninternal/travelbase-ds/components/form/SelectInput';
import { PageFragment } from '../generated/graphql';
import styled from 'styled-components';
import { Locale } from '@oberoninternal/travelbase-website/dist/entities/Locale';
import { useIntl } from 'react-intl';
import getAgendaPageSlugByLocale from '../utils/getAgendaPageSlugByLocale';
import getCompaniesPageSlugByLocale from '../utils/getCompanyPageSlugByLocale';
import brandConfig from '../constants/brandConfig';
import useGetPageSlugByLocale from '@oberoninternal/travelbase-website/dist/hooks/useGetPageSlugByLocale';

interface Props extends Pick<PageFragment, 'localized'> {
    open: boolean;
}

interface LanguageOption {
    value: string;
    icon: ReactNode;
}

const StyledCaret = styled(Caret)`
    width: 1.2rem;
    height: 1.2rem;
    color: ${({ theme }) => theme.colors.primary['80']}!important;
    transition: color 0.5s ease-in-out, rotate 0.5s ease-in-out;
`;

const LangSwitch: FC<React.PropsWithChildren<Props>> = ({ localized, open }) => {
    const { getPageSlugByLocale } = useGetPageSlugByLocale();
    const router = useRouter();
    const { asPath, locale, locales, query } = router;
    const { formatMessage } = useIntl();
    const routerLocale = locale as Locale;
    const getLink = useCallback(
        (lang: string) => {
            if (lang === locale) {
                return asPath;
            }

            const accommodationPageSlug = getPageSlugByLocale('ACCOMMODATION', lang);
            const activityPageSlug = getPageSlugByLocale('ACTIVITY', lang);
            const companyPageSlug = getPageSlugByLocale('COMPANY', lang);
            const alt = localized
                ?.map(alternate => {
                    const { pathname: path } = alternate?.url ? new URL(alternate.url) : { pathname: '' };
                    return { locale: alternate?.language, path };
                })
                .find(({ locale: alternateLocale }) => alternateLocale === lang);
            if (typeof localized !== 'undefined') {
                return alt?.path;
            }
            const pathParts = asPath.split('/');
            switch (pathParts[1]) {
                case 'accommodaties':
                case 'accommodations':
                case 'unterkuenfte':
                    pathParts[1] = accommodationPageSlug;
                    break;
                case 'activiteit':
                case 'activity':
                case 'aktivitat':
                    pathParts[1] = activityPageSlug;
                    break;
                case 'onderneming':
                case 'company':
                case 'firma':
                    pathParts[1] = companyPageSlug;
                    break;
                case 'excursies':
                case 'excursions':
                case 'ausfluge':
                    pathParts[1] = getAgendaPageSlugByLocale(lang as Locale);
                    break;
                case 'companies':
                case 'bedrijven':
                case 'firmen':
                    pathParts[1] = getCompaniesPageSlugByLocale(lang as Locale);
                    break;
                default:
                    break;
            }

            return pathParts.join('/');
        },
        [asPath, getPageSlugByLocale, locale, localized]
    );

    const languageOptions: LanguageOption[] =
        locales?.map(language => {
            switch (language) {
                case `en`:
                    return { value: 'en', icon: <FlagEN /> };
                case `de`:
                    return { value: 'de', icon: <FlagDE /> };
                default:
                    return { value: 'nl', icon: <FlagNL /> };
            }
        }) ?? [];
    return (
        <StyledSelectInputField
            hideChoices
            rounded={brandConfig.components?.header?.langSwitch.rounded}
            variant="small"
            options={languageOptions}
            name="Language"
            aria-label={formatMessage({ defaultMessage: 'Kies een taal' })}
            thinShadow
            isSearchable={false}
            value={languageOptions.find(language => language.value === routerLocale) ?? languageOptions[0]}
            ToggleComponent={StyledCaret}
            onChange={(option: OptionType) => {
                const link = getLink(option.value);
                if (asPath === '/') {
                    router.push({ path: '/', query }, '/', { locale: option.value });
                }
                if (link) {
                    router.push({ path: '/', query }, link, { locale: option.value });
                }
            }}
            classNamePrefix="lang"
            open={open}
        />
    );
};

const StyledSelectInputField = styled(SelectInputField)<{ open: boolean }>`
    min-width: 6rem;
    display: block;
    box-shadow: 0 2px 4px 0 rgba(59, 118, 160, 0.02), 0 4px 12px 0 rgba(59, 118, 160, 0.04);
    border-radius: 2.8rem;
    cursor: pointer;

    [class^='lang__control'] {
        border-radius: ${({ theme }) => theme.radius.button};
        > div:first-child {
            padding: 0 2px;
            border-radius: 3px;
        }
    }
    [class^='lang__menu'] {
        padding: 0;
        background: ${({ theme }) => theme.colors.neutral['0']};
        z-index: 99;
    }
    [class^='lang__menu-'] {
        border: 1px solid ${({ theme }) => theme.colors.neutral['20']};
        box-shadow: none;
        border-radius: 0.6rem;
    }
    [class^='lang__option'] {
        width: 4.7rem;
        margin: 0.2rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        [class^='lang__option'] {
            width: 5.4rem;
        }
    }

    > div {
        min-height: 3.2rem;
        padding: 0;
        overflow: visible;

        > div:first-child {
            overflow: visible;
            > div {
                overflow: visible;
                border-radius: 0.3rem;
            }
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        display: ${({ open }) => (open ? 'block' : 'none')};

        min-width: 5.3rem;

        > div {
            height: 3.2rem;
            padding: 0;
        }

        .flag {
            width: 2rem !important;
            height: 2rem !important;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        margin-right: 0.8rem;
    }
`;

export default LangSwitch;
