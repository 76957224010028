import { gql } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import { FooterItemsFragment } from '../generated/graphql';
import styled from 'styled-components';
import { Box } from '@rebass/grid';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import Facebook from './icons/social/Facebook';
import Twitter from './icons/social/Twitter';
import Icon from './Icon';
import Instagram from './icons/social/Instagram';
import Youtube from './icons/social/Youtube';
import Pinterest from './icons/social/Pinterest';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Page from '@oberoninternal/travelbase-website/dist/components/Page';
import Allianz from './icons/logos/Allianz';
import IDeal from './icons/logos/IDeal';
import Paypal from './icons/logos/Paypal';
import VVV from './icons/social/VVV';
import LinkField from './LinkField';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import brandConfig from '../constants/brandConfig';

export const fragment = gql`
    fragment FooterItems on footer_footerCategory_Entry {
        title
        children {
            ...FooterSubmenu
        }
    }

    fragment FooterSubmenu on footer_footerLink_Entry {
        title
        __typename
        menuLink {
            ...LinkField
        }
    }
`;

interface Props {
    footer: FooterItemsFragment[];
}

const Footer: FC<React.PropsWithChildren<Props>> = ({ footer }: Props) => {
    const footerItems = [...footer];
    const lastItems = footerItems.pop();
    const { formatMessage } = useIntl();
    const [stickybarActive, setStickybarActive] = useState(false);
    const router = useRouter();
    useEffect(() => {
        setStickybarActive(!!document.getElementsByClassName('travelbase-stickybar').length);
    }, [router.pathname]);

    return (
        <Page>
            <FooterWrapper style={{ paddingBottom: stickybarActive ? 'var(--booking-bar-height)' : undefined }}>
                <ContentWrapper>
                    <FooterMenu>
                        {footerItems.map((item, key, arr) => (
                            <FooterColumn key={key}>
                                <SubmenuTitle variant="tiny" elementType="h2">
                                    {item.title}
                                </SubmenuTitle>
                                {brandConfig.components?.footer.contactInformation &&
                                    brandConfig.address &&
                                    arr.length - 1 === key && (
                                        <Address>
                                            <ContactItem>
                                                <FormattedMessage defaultMessage="Telefoon" />:{' '}
                                                {brandConfig.phoneNumber}
                                            </ContactItem>
                                            <ContactItem>{brandConfig.address?.street}</ContactItem>
                                            <ContactItem>
                                                {brandConfig.address?.postcode} {brandConfig.address?.location}
                                            </ContactItem>
                                        </Address>
                                    )}
                                <SubmenuList>
                                    {item.children?.map((submenuItem, i) => {
                                        if (!submenuItem || submenuItem?.__typename !== 'footer_footerLink_Entry') {
                                            return null;
                                        }

                                        return (
                                            submenuItem?.menuLink &&
                                            submenuItem.title && (
                                                <SubmenuListItem key={i}>
                                                    <LinkField link={submenuItem.menuLink}>
                                                        {submenuItem.title}
                                                    </LinkField>
                                                </SubmenuListItem>
                                            )
                                        );
                                    })}
                                </SubmenuList>
                            </FooterColumn>
                        ))}
                        <FooterColumn last>
                            <Social>
                                <SubmenuTitle variant="tiny" elementType="h2">
                                    <FormattedMessage defaultMessage="Volg ons" />
                                </SubmenuTitle>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={formatMessage({
                                        id: 'facebook',
                                        defaultMessage: 'https://www.facebook.com/groetenvanterschelling',
                                    })}
                                    aria-label="Facebook"
                                >
                                    <StyledIcon icon={<StyledFacebook />} rounded />
                                </a>

                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={brandConfig.socialLinks.instagram}
                                    aria-label="Instagram"
                                >
                                    <StyledIcon icon={<Instagram />} rounded />
                                </a>
                                {brandConfig.socialLinks.pinterest && (
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={brandConfig.socialLinks.pinterest}
                                        aria-label="Pinterest"
                                    >
                                        <StyledIcon icon={<Pinterest />} rounded />
                                    </a>
                                )}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={brandConfig.socialLinks.youtube}
                                    aria-label="Youtube"
                                >
                                    <StyledIcon icon={<Youtube />} rounded />
                                </a>
                                {brandConfig.socialLinks.twitter && (
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={brandConfig.socialLinks.twitter}
                                        aria-label="Twitter"
                                    >
                                        <StyledIcon icon={<Twitter />} rounded />
                                    </a>
                                )}
                            </Social>
                            <Box pt={5}>
                                <LogoTitle variant="tiny" elementType="h2">
                                    <FormattedMessage defaultMessage="Zeker op vakantie" />
                                </LogoTitle>
                                <LogoWrapper>
                                    <Allianz />
                                    <Paypal />
                                    <IDeal />
                                    <VVV />
                                </LogoWrapper>
                            </Box>
                        </FooterColumn>
                    </FooterMenu>
                </ContentWrapper>

                <StyledHr />
                <ContentWrapper>
                    <LastFooter>
                        <SourcesMenu>
                            {lastItems?.children?.map((item, key) => {
                                if (!item || item?.__typename !== 'footer_footerLink_Entry') {
                                    return null;
                                }

                                return (
                                    item?.menuLink &&
                                    item.title && (
                                        <LinkField link={item.menuLink} key={key}>
                                            {item.title}
                                        </LinkField>
                                    )
                                );
                            })}
                        </SourcesMenu>
                        <Body>
                            © {new Date().getFullYear()} {brandConfig.general.siteTitle}{' '}
                        </Body>
                    </LastFooter>
                </ContentWrapper>
            </FooterWrapper>
        </Page>
    );
};

export default Footer;

const FooterWrapper = styled.footer`
    background-color: ${({ theme }) => theme.tokens?.footer.wrapper.backgroundColor};
`;

const FooterMenu = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
        flex-wrap: wrap;
        padding: 3.2rem 0;
    }
`;

const Address = styled.div`
    margin-bottom: 2rem;
`;

const ContactItem = styled.p`
    margin: 0.2rem 0;
    color: #6f6f6f;
    font-weight: 400 !important;
    font-size: 14px;
    white-space: nowrap;
`;

const FooterColumn = styled.div<{ last?: boolean }>`
    padding: 4rem 0 3.2rem 0;
    border-bottom: 0.1rem solid ${({ theme }) => theme.tokens?.footer.divider.color};
    ${({ last }) => last && `border-bottom: 0 solid transparent`};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-right: 2.4rem;
        ${({ last }) => (last ? `display: flex;` : `display: block;`)};
        ${({ last }) => (last ? `width: 50%; padding: 0;` : `width: 25%;`)};
        border-bottom: 0 solid transparent;
    }
`;

const SubmenuTitle = styled(Title)`
    color: ${({ theme }) => theme.tokens?.footer.color};
    margin-bottom: 1.6rem;
    flex-basis: 100%;
`;

const Social = styled.div`
    flex-wrap: wrap;
    display: flex;
    padding-bottom: 3.2rem;
    border-bottom: 0.1rem solid ${({ theme }) => theme.tokens?.footer.divider.color};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        border-bottom: 0 solid transparent;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding: 2.4rem 2.4rem 2.4rem 0;
        flex: 0 0 50%;
    }
`;

const SubmenuList = styled.ul`
    margin: 0;
    padding: 0;
`;

const SubmenuListItem = styled.li`
    margin: 0.2rem 0;

    a {
        color: ${({ theme }) => theme.tokens?.footer.color}!important;
        text-decoration: none !important;
        font-weight: 400 !important;
        font-size: 14px;
        &:hover {
            text-decoration: underline !important;
        }
    }
`;

const StyledFacebook = styled(Facebook)`
    width: 0.9rem;
    height: 1.6rem;
`;

const LastFooter = styled.div`
    color: ${({ theme }) => theme.colors.neutral['30']};
    padding: 4.5rem 0;

    a {
        color: ${({ theme }) => theme.tokens?.footer.color};
        text-decoration: none;
        border-bottom: 0.1rem solid ${({ theme }) => theme.tokens?.footer.color};
        font-weight: 600;
        font-size: 14px;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        display: flex;
        justify-content: space-between;
    }
`;

const StyledHr = styled.hr`
    border-top: 0.1rem solid ${({ theme }) => theme.tokens?.footer.divider.color};
    border-bottom: none;
    border-right: none;
    border-left: none;
    margin-left: ${({ theme }) => theme.tokens?.footer.divider.margin};
    margin-right: ${({ theme }) => theme.tokens?.footer.divider.margin};
`;
const SourcesMenu = styled.div`
    padding-bottom: 2.3rem;

    > * + * {
        margin-left: 3.2rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        padding-bottom: 0;
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    color: ${({ theme }) => theme.tokens?.footer.color};

    svg {
        height: 2.4rem;
        margin: 2.4rem 1.2rem 2.4rem 0;
    }
`;

const LogoTitle = styled(Title)`
    color: ${({ theme }) => theme.tokens?.footer.color};
`;

const StyledIcon = styled(Icon)`
    margin-right: 0.8rem;
`;
