import { gql } from '@apollo/client';
import { HeroTransformFragment, HighlightTransformFragment, TileTransformFragment } from '../generated/graphql';
import { HeroProps } from '../components/Hero';
import { ImgProps } from '@oberoninternal/travelbase-website/dist/components/Img';

const propsTransformer = (transform: TileTransformFragment | HeroTransformFragment, sizes?: string) => {
    const { placeholderUrl, extension, title, url } = transform;
    let { srcset, srcsetWebp } = transform;

    if ('srcsetMobile' in transform) {
        srcset = `${transform.srcsetMobile}, ${srcset}`;
    }

    if ('srcsetMobileWebp' in transform) {
        srcsetWebp = `${transform.srcsetWebp}, ${srcset}`;
    }

    return {
        placeholder: placeholderUrl ?? undefined,
        alt: title ?? undefined,
        sources: [
            {
                srcSet: srcsetWebp ?? undefined,
                type: 'image/webp',
                sizes,
            },
            {
                srcSet: srcset ?? undefined,
                type: `image/${extension}`,
                sizes,
            },
        ],
        focalPoint: (transform.focalPoint as number[]) ?? undefined,
        fallback: {
            alt: title ?? undefined,
            src: url ?? undefined,
        },
    };
};

export const heroTransformToProps = (transform: HeroTransformFragment): HeroProps => ({
    ratio: 5 / 16,
    mobileRatio: 3 / 4,
    ...propsTransformer(transform),
});

export const highlightTransformToProps = (transform: HighlightTransformFragment): ImgProps => ({
    layout: 'ratio',
    ratio: 1,
    ...propsTransformer(transform),
});

export const tileTransformToProps = (
    transform: TileTransformFragment,
    sizes = '100vw'
): ImgProps & { focalPoint?: number[] } => ({
    layout: 'fill',
    ...propsTransformer(transform, sizes),
});

// the goal of these fragments is to provide pre-configured transforms
// that largely follow the same specs and names as TOR's own buildin transforms.

export const fragments = gql`
    fragment HeroTransform on AssetInterface {
        focalPoint
        title
        placeholderUrl: url @transform(width: 48, height: 15, mode: "crop", immediately: false)
        extension
        url
        ogImageUrl: url @transform(width: 1200, immediately: false)
        srcset: url
            @transformsrcset(
                sizes: ["1024w", "1920w", "2560w"]
                width: 1920
                height: 640
                mode: "crop"
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["1024w", "1920w", "2560w"]
                width: 1920
                height: 640
                mode: "crop"
                immediately: false
            )
        srcsetMobile: url
            @transformsrcset(sizes: ["320w", "640w"], width: 640, height: 480, mode: "crop", immediately: false)
        srcsetMobileWebp: url
            @transformsrcset(
                sizes: ["320w", "640w"]
                width: 640
                height: 480
                mode: "crop"
                format: "webp"
                immediately: false
                quality: 70
            )
    }

    fragment TileTransform on AssetInterface {
        focalPoint
        title
        placeholderUrl: url @transform(width: 64, height: 45, mode: "crop", immediately: false)
        extension
        url
        srcset: url
            @transformsrcset(
                sizes: ["64w", "320w", "640w"]
                width: 1920
                height: 1080
                mode: "crop"
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["64w", "320w", "640w"]
                width: 1920
                height: 1080
                mode: "crop"
                format: "webp"
                immediately: false
                quality: 70
            )
    }

    fragment GalleryTransform on AssetInterface {
        focalPoint
        title
        placeholderUrl: url @transform(width: 48, height: 27, mode: "crop", immediately: false)
        extension
        url
        width
        height
        srcset: url
            @transformsrcset(
                sizes: ["320w", "640w", "1024w", "1920w", "2560w"]
                width: 1920
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["320w", "640w", "1024w", "1920w", "2560w"]
                width: 1920
                format: "webp"
                immediately: false
                quality: 70
            )
    }

    fragment HighlightTransform on AssetInterface {
        width
        height
        focalPoint
        title
        extension
        url
        srcset: url
            @transformsrcset(
                sizes: ["64w", "128w", "80w", "160w"]
                width: 64
                height: 64
                mode: "crop"
                immediately: false
                quality: 70
            )
        srcsetWebp: url
            @transformsrcset(
                sizes: ["64w", "128w", "80w", "160w"]
                width: 64
                height: 64
                mode: "crop"
                format: "webp"
                immediately: false
                quality: 70
            )
        placeholderUrl: url @transform(width: 4, height: 4, mode: "crop", immediately: false, quality: 70)
    }
`;
