import React, { FC } from 'react';
import { Flex } from '@rebass/grid';
// import { FormattedMessage, useIntl } from 'react-intl';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import styled from 'styled-components';
import Logo from './icons/Logo';
import { FormattedMessage } from 'react-intl';

const PreFooter: FC<React.PropsWithChildren<unknown>> = () => (
    <Container mb={[4, 8]} mt={[4, 8]}>
        <Item>
            <Body variant="regular">
                <FormattedMessage defaultMessage="De zekerheid van:" />
            </Body>
            <>
                <Flag src={'/static/img/flag.svg'} alt="VVV Terschelling" width={64} height={34} />
                <StyledLogo />
            </>
        </Item>
        <Item>
            <Body variant="regular">
                <FormattedMessage defaultMessage="Veilig betalen met:" />
            </Body>
            <Ideal src={'/static/img/ideal-logo.svg'} alt="VVV Terschelling" width={64} height={34} />
        </Item>
    </Container>
);

const Container = styled(Flex)`
    border-top: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        flex-direction: row;
    }
`;

const Flag = styled.img`
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.2));
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        position: relative;
        display: inline-block;
    }
`;

const Ideal = styled.img`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        position: relative;
        display: inline-block;
    }
`;

const Item = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        flex-direction: row;
    }
`;

const StyledLogo = styled(Logo)<{ isTop: boolean }>`
    transition: color 0.3s ease-in-out;
    max-width: 11.7rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        ${({ isTop }) => isTop && `margin-bottom: 2.4rem;`}
    }
`;

export default PreFooter;
