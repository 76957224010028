const subscribeToNewsletter = (email: string, locale: string) => {
    fetch('https://m7.mailplus.nl/genericservice/code/servlet/Redirect', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
            field10325: email,
            field10326: locale,
            userId: '370074',
            formEncId: '8y585rpNz2gZLVFrvcCh',
            viewMode: 'STATICINTEGRATION',
            redir: 'formAdmin2',
        }),
    });
};

export default subscribeToNewsletter;
