import Modal from 'react-modal';
import styled from 'styled-components';
import React, { FC, useCallback, useState } from 'react';
import cookies from 'js-cookie';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import transparentize from 'polished/lib/color/transparentize';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import { FormattedMessage } from 'react-intl';
import Link from './Link';

const CookieConsent = () => {
    const [isOpen, setIsOpen] = useState(typeof cookies.get('cookie_consent') === 'undefined');
    const close = useCallback(() => {
        cookies.set('cookie_consent', 'false');
        setIsOpen(false);

        window.dataLayer = window.dataLayer ?? [];
        window.dataLayer.push({
            event: 'cookie_refused',
        });
    }, []);

    return (
        <StyledModal isOpen={isOpen}>
            <Container>
                <StyledTitle>
                    <FormattedMessage defaultMessage="VVV Terschelling gebruikt cookies" />
                </StyledTitle>
                <Body>
                    <FormattedMessage defaultMessage="Deze site gebruikt cookies en vergelijkbare technieken om de website te verbeteren en je relevante advertenties te kunnen laten zien. " />
                    <br />
                    <FormattedMessage
                        values={{
                            a: (...children) => (
                                <Link href={`/diversen/cookies`}>
                                    <a>{children}</a>
                                </Link>
                            ),
                        }}
                        defaultMessage="Meer informatie vind je in ons <a>cookiebeleid</a>"
                    />
                </Body>
                <Body>
                    <FormattedMessage
                        defaultMessage="Indien je kiest voor <a>weigeren</a>, plaatsen we alleen functionele en analytische cookies."
                        values={{
                            a: (...children) => (
                                <a
                                    href="#"
                                    onClick={e => {
                                        e.preventDefault();
                                        close();
                                    }}
                                >
                                    {children}
                                </a>
                            ),
                        }}
                    />
                </Body>
                <Button
                    rounded
                    size="large"
                    className="accept"
                    onClick={() => {
                        cookies.set('cookie_consent', 'true', { expires: 365 });
                        setIsOpen(false);
                        window.dataLayer = window.dataLayer ?? [];
                        window.dataLayer.push({
                            event: 'cookie_accepted',
                        });
                    }}
                >
                    <span className="accept">
                        <FormattedMessage defaultMessage="Akkoord" />
                    </span>
                </Button>
            </Container>
        </StyledModal>
    );
};

const ModalAdapter: FC<React.PropsWithChildren<Modal.Props & { className?: string }>> = ({ className, ...props }) => {
    const contentClassName = `${className}__content`;
    const overlayClassName = `${className}__overlay`;
    return (
        <Modal
            portalClassName={className}
            className={contentClassName}
            overlayClassName={overlayClassName}
            closeTimeoutMS={500}
            {...props}
        />
    );
};

// Modal.setAppElement('#__next');

export default CookieConsent;

const StyledModal = styled(ModalAdapter)<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${({ theme }) => theme.zIndices.modal};
    ${({ isOpen }) => !isOpen && `pointer-events: none;`}

    &__overlay {
        background: ${({ theme }) => transparentize(0.56, theme.colors.neutral[80])};
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 0.8rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        opacity: 0;
        transition: opacity 0.25s;

        &.ReactModal__Overlay--after-open {
            opacity: 1;
        }
        &.ReactModal__Overlay--before-close {
            opacity: 0;
        }

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            justify-content: center;
            align-items: center;
        }
    }

    &__content {
        transition: transform 0.5s ease;
        transform: translateY(100%);
        outline: none;

        &.ReactModal__Content--after-open {
            transform: translateY(0);
        }
        &.ReactModal__Content--before-close {
            transform: translateY(100%);
        }

        @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
            max-width: 56.8rem;
            transform: translateY(20%);

            &.ReactModal__Content--before-close {
                transform: translateY(20%);
            }
        }
    }
`;

const Container = styled.div`
    border-radius: 0.8rem;
    background: ${({ theme }) => theme.colors.neutral[0]};
    padding: 2.4rem 1.8rem;
    display: flex;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.colors.neutral[20]};
    box-shadow: 0 2px 4px 0 ${({ theme }) => transparentize(0.02, theme.colors.neutral[80])},
        0 4px 12px 0 transparentize(0.04, theme.colors.neutral[80]);

    button {
        margin-top: 2.4rem;
    }

    > * + * {
        margin-top: 1.6rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding: 4rem 3.2rem;
        display: block;

        button {
            min-width: 19.8rem;
        }
    }
`;

const StyledTitle = styled(Title)`
    color: ${({ theme }) => theme.colors.primary[80]};
`;
