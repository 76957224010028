import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    .ext-search-box__container{
        & * {
            --buttonContainerRadius: 0.5rem !important;

        }
    }
    body {
        --logoOffsetLeft: 15.3rem;
        --booking-bar-height: 10rem;

        display: block !important;
        color: ${({ theme }) => theme.colors.primary[80]};

        p, h1, h2, h3, h4, h5, li {
            a {
                color: ${({ theme }) => theme.colors.primary[60]};
                font-weight: 600;
                text-decoration: underline !important;

                :hover {
                    color: ${({ theme }) => theme.colors.primary[60]};
                }
            }
        }
    }


    body.is-checkout {
        padding-bottom: 7rem;
    }

    /* This code is subject to LICENSE in root of this repository */

    /* Used to detect in JavaScript if apps have loaded styles or not. */
    :root {
        --reach-dialog: 1;
    }

    [data-reach-dialog-overlay] {
        background: hsla(0, 0%, 0%, 0.33);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
    }

    [data-reach-dialog-content] {
        width: 50vw;
        margin: 10vh auto;
        background: white;
        padding: 2rem;
        outline: none;
    }

    [class*='TagButton__StyledButton-'] {
        background: ${({ theme }) => theme.colors.tertiary[50]};
        color: ${({ theme }) => theme.colors.neutral[0]};
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) and (max-width: ${({ theme }) =>
    theme.mediaQueries.l}) {
        [class*='ActivityPlannerTimeslots__StyledSlotBoxWrapper-'] {
            margin-top: 5rem;
        }
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        [class*='ActivityPlannerTimeslots__StyledSlotBoxWrapper-'] {
            margin-top: 4rem;
        }
        [class*='ActivityPlannerTimeslots__DateBody-'] {
            margin: 1rem 0 1rem 0;
        }
    }

    @media screen and (min-width:  ${({ theme }) => theme.mediaQueries.s}) {
        [class*='ActivityPlanner__PlannerWrapper-'] {
            margin: 1rem;
        }
    }

    [class*='ActivityPlanner__PlannerCol-'] {
        padding-top: 0;
    }

    button.ext-deskmode__button {
        top: 12.5rem;
    }
`;
export default GlobalStyle;
