import WebsiteBrandConfig from './WebsiteBrandConfig';

const terschellingBrandConfig: WebsiteBrandConfig = {
    general: {
        siteTitle: 'VVV Terschelling',
    },
    components: {
        currentTheme: 'Terschelling',
        header: {
            webshop: true,
            searchButton: false,
            langSwitch: {
                rounded: false,
            },
        },
        footer: {
            contactInformation: true,
        },
        pages: {
            readMore: false,
            seeAlso: true,
        },
        cards: {
            readMore: true,
        },
    },
    socialLinks: {
        facebook: 'https://www.facebook.com/groetenvanterschelling',
        instagram: 'https://www.instagram.com/vvvterschelling/',
        pinterest: '',
        youtube: 'https://www.youtube.com/user/VVVTerschelling',
        twitter: 'https://twitter.com/terschellingvvv',
    },
    phoneNumber: '+31 562 443000',
    address: {
        street: 'Willem Barentszkade 19a',
        postcode: '8881 BC',
        location: 'Terschelling West',
    },
};

export default terschellingBrandConfig;
