const getContactLink = (locale: string) => {
    switch (locale) {
        case 'nl':
            return 'contact';
        case 'en':
            return 'contact';
        case 'de':
            return 'kontakt';
        default:
            throw new Error(`getContactLink: unknown locale ${locale}`);
    }
};
export default getContactLink;
