import React, { SVGAttributes } from 'react';

const Allianz = (props: SVGAttributes<SVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96 25" {...props}>
        <defs />
        <defs>
            <path id="allianz-a" d="M0 .12h14.423v15.483H0z" />
            <path id="allianz-c" d="M.074.062h23.773v23.772H.074z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(0 3.414)">
                <use xlinkHref="#allianz-a" />
                <path
                    fill="currentColor"
                    d="M3.766 15.603l1.116-3.735h4.409l1.045 3.735h4.087l-4.06-14.252C10.128.51 9.618.121 8.734.121h-5.78v1.346h.486c.603 0 .93.235.93.696 0 .304-.068.559-.307 1.253L0 15.603h3.766zm4.97-6.269H5.461L7.204 3.65l1.531 5.685z"
                    mask="url(#allianz-b)"
                />
            </g>
            <path
                fill="currentColor"
                d="M21.332 19.017V4.72c0-.745-.438-1.186-1.16-1.186H16.6v1.347h.232c.603 0 .857.255.857.863v13.273h3.643zM27.667 19.017V4.72c0-.745-.443-1.186-1.16-1.186h-3.575v1.347h.228c.607 0 .862.255.862.863v13.273h3.645zM33.957 19.017V8.595c0-.743-.444-1.182-1.164-1.182h-3.526v1.32h.207c.604 0 .858.277.858.882v9.402h3.625zm.067-14.07c0-1.038-.768-1.666-2.088-1.666-1.3 0-2.093.628-2.093 1.666 0 1.051.794 1.672 2.093 1.672 1.32 0 2.088-.62 2.088-1.672zM37.035 10.735c1.21-.58 2.534-.907 3.576-.907.977 0 1.327.348 1.327 1.363v.26c-1.485.234-1.95.33-2.787.513a8.356 8.356 0 00-1.372.44c-1.464.587-2.155 1.717-2.155 3.457 0 2.205.95 3.27 2.922 3.27.72 0 1.372-.137 1.954-.366.508-.227.81-.417 1.555-.997v.16c0 .72.37 1.089 1.091 1.089h3.317v-1.25H46.3c-.625 0-.855-.28-.855-1.02V11.22c0-2.947-1.023-3.973-3.974-3.973-.95 0-1.879.12-2.782.328-.91.208-1.376.394-2.46.929l.806 2.23zm4.903 2.708V15.7c-.607.627-1.347 1.002-1.972 1.002-.653 0-.882-.321-.882-1.191 0-.858.182-1.25.834-1.502.485-.212 1.113-.396 2.02-.566zM52.754 19.017v-7.846c.606-.673 1.23-.978 1.95-.978.769 0 .953.305.953 1.47v7.354h3.62v-7.89c0-1.835-.133-2.417-.715-3.04-.49-.537-1.232-.813-2.186-.813-1.418 0-2.415.44-3.756 1.694v-.464c0-.721-.377-1.09-1.118-1.09h-3.439v1.319h.209c.603 0 .862.277.862.882v9.402h3.62zM69.998 16.58h-5.06l4.83-6.773V7.412h-7.566c-.86 0-1.253.393-1.253 1.255v2.108h1.344v-.207c0-.534.279-.788.855-.788h2.605l-4.876 6.85v2.387h9.121v-2.436z"
            />
            <g transform="translate(71.598 .103)">
                <use xlinkHref="#allianz-c" />
                <path
                    fill="currentColor"
                    d="M23.847 11.95c0-6.796-5.085-11.888-11.885-11.888C5.158.062.074 5.154.074 11.95c0 6.806 5.084 11.885 11.888 11.885 6.8 0 11.885-5.079 11.885-11.885zm-2.02 0c0 5.78-4.155 9.984-9.865 9.984-5.712 0-9.867-4.204-9.867-9.984 0-5.782 4.155-9.985 9.867-9.985 5.71 0 9.865 4.228 9.865 9.985zM8.734 18.618V7.006h-2.02c-1 0-1.39.37-1.39 1.395v10.217h3.41zm6.457 0h3.41V8.401c0-1.025-.395-1.395-1.394-1.395H15.19v11.612zm-1.514 0V5.498c0-1.045-.37-1.396-1.387-1.396H9.22v1.465h.183c.702 0 .838.159.838 1.002v12.05h3.436z"
                    mask="url(#allianz-d)"
                />
            </g>
        </g>
    </svg>
);

export default Allianz;
