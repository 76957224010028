import { Locale } from '@oberoninternal/travelbase-website/dist/entities/Locale';

const getCompaniesPageSlugByLocale = (locale: Locale): string => {
    switch (locale) {
        case 'nl':
            return 'bedrijven';
        case 'en':
            return 'companies';
        case 'de':
        default:
            return 'firmen';
    }
};

export default getCompaniesPageSlugByLocale;
