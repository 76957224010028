import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import HamburgerMenu from './HamburgerMenu';
import Link from './Link';
import MainNavigation, { EntryIds, MenuItemPageFragment } from './MainNavigation';
import Logo from './icons/Logo';
import { MenuItemPageFieldsFragment, MenuItemsFragment, PageFragment } from '../generated/graphql';
import ExpandedNavigation from './ExpandedNavigation';
import Page from '@oberoninternal/travelbase-website/dist/components/Page';
import useRouterEvent from '@oberoninternal/travelbase-website/dist/hooks/useRouterEvent';
import { transparentize } from 'polished';
import { useRouter } from 'next/router';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import SearchBox from './SearchBox';
import { FormattedMessage } from 'react-intl';
import SearchField from './SearchField';
import LangSwitch from './LangSwitch';
import TertiaryButton from './TertiaryButton';
import NavWrapper from './layout/NavWrapper';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import brandConfig from '../constants/brandConfig';
import StoreIcon from './icons/StoreIcon';
import { useDeviceSize } from '@oberoninternal/travelbase-ds/context/devicesize';

interface Props extends Pick<PageFragment, 'localized'> {
    menu: MenuItemsFragment[];
    searchLink?: string;
}

const traverse = (
    item: MenuItemPageFragment,
    needles: EntryIds,
    level: number,
    initial: MenuItemPageFieldsFragment[]
): MenuItemPageFragment[] => {
    const needle = needles[level];
    const selected =
        // me or my children
        item.id === needle
            ? item
            : item.children?.find(child => child?.__typename === 'pages_page_Entry' && child.id === needle);
    if (selected?.__typename === 'pages_page_Entry') {
        return traverse(selected, needles, level + 1, [...initial, selected]);
    }
    return initial;
};

const Header: FC<React.PropsWithChildren<Props>> = ({ menu, localized, searchLink }: Props) => {
    const [open, setOpen] = useState(false);
    const { asPath } = useRouter();

    const [selectedPageIds, setSelectedPageIds] = useState<EntryIds>([]);
    const selectedItem = useMemo(
        () =>
            menu?.find(item => {
                const itemPage = item.menuLink?.element;
                return itemPage?.__typename === 'pages_page_Entry' && itemPage.id === selectedPageIds[0];
            }),
        [menu, selectedPageIds]
    );

    const selectedPages = useMemo(() => {
        const selectedPage = selectedItem?.menuLink?.element;
        if (selectedPage?.__typename !== 'pages_page_Entry') {
            return [];
        }
        return traverse(selectedPage, selectedPageIds, 0, []);
    }, [selectedItem, selectedPageIds]);
    const { open: searchModalOpen, onOpen: onOpenModal, onClose } = useSesame(false);

    const handleRouteChange = useCallback(() => setOpen(false), []);
    useRouterEvent('routeChangeStart', handleRouteChange);

    const hideButtonPages = [
        'checkout/extras/',
        'checkout/details/',
        'checkout/payment/',
        '/accommodatie/',
        '/overnachten/',
        '/search/',
        '/uebernachten/',
        '/spend-the-night/',
    ];
    const hideButton = hideButtonPages.some(hideButtonPage => asPath.includes(hideButtonPage));
    const deviceSize = useDeviceSize();
    return (
        <>
            <HeaderWrapper
                onMouseLeave={() => {
                    setSelectedPageIds([]);
                    setOpen(false);
                }}
            >
                <StyledPage>
                    <StyledNavWrapper>
                        <LogoWrapper>
                            <Link href={`/`} passHref>
                                <LogoLinkTerschelling>
                                    <Flag src={'/static/img/flag.svg'} alt="VVV Terschelling" width={64} height={214} />
                                    <LogoContainer>
                                        <StyledLogo />
                                    </LogoContainer>
                                </LogoLinkTerschelling>
                            </Link>
                        </LogoWrapper>
                        <NavigationSearchWrapper>
                            <MainNavigation
                                open={open}
                                setOpen={setOpen}
                                menu={menu}
                                selectedPageIds={selectedPageIds}
                                setSelectedPageIds={setSelectedPageIds}
                            />
                            {deviceSize !== 'mobile' && <SearchField menuIsOpen={open} searchLink={searchLink} />}
                        </NavigationSearchWrapper>
                        <Icons open={open}>
                            <LangSwitch open={open} localized={localized} />

                            {!hideButton && brandConfig.components?.header?.searchButton && (
                                <>
                                    <StyledButton
                                        className="lt-m"
                                        rounded
                                        size="large"
                                        onClick={onOpenModal}
                                        menuIsOpen={open}
                                    >
                                        <span>
                                            <FormattedMessage defaultMessage="Boeken" />
                                        </span>
                                    </StyledButton>
                                    <StyledButton
                                        className="gt-m"
                                        rounded
                                        size="large"
                                        onClick={onOpenModal}
                                        menuIsOpen={open}
                                    >
                                        <span>
                                            <FormattedMessage defaultMessage="Zoek en Boek" />
                                        </span>
                                    </StyledButton>
                                </>
                            )}

                            {deviceSize === 'mobile' && <SearchField menuIsOpen={open} searchLink={searchLink} />}

                            {brandConfig.components?.header?.webshop && (
                                <StyledWebshopButton
                                    variant="outline"
                                    size="large"
                                    href="https://webwinkelterschelling.nl/"
                                    target="_blank"
                                    as="a"
                                >
                                    <StoreIcon />
                                    <span className="gt-s">
                                        <FormattedMessage defaultMessage="Webshop" />
                                    </span>
                                </StyledWebshopButton>
                            )}
                            <StyledHamburgerMenu open={open} openMenu={setOpen} />
                        </Icons>
                    </StyledNavWrapper>
                </StyledPage>

                <ExpandedNavigation
                    selectedPages={selectedPages}
                    setSelectedPageIds={setSelectedPageIds}
                    menu={menu}
                    open={open}
                    setOpen={setOpen}
                />
            </HeaderWrapper>
            <Overlay
                isActive={open}
                onClick={() => {
                    setOpen(false);
                    setSelectedPageIds([]);
                }}
            />
            <SearchBox onClose={onClose} open={searchModalOpen} />
        </>
    );
};

export default Header;

const NavigationSearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.xl}) and (min-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        flex-direction: row-reverse;
        margin-left: auto;
    }
`;

const Overlay = styled.div<{ isActive: boolean }>`
    opacity: ${({ isActive }) => (isActive ? 0.56 : 0)};
    pointer-events: ${({ isActive }) => (isActive ? 'unset' : 'none')};
    transition: opacity 0.25s;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: ${({ theme }) => theme.colors.neutral[80]};
    z-index: ${({ theme }) => theme.zIndices.overlay};
`;

const StyledButton = styled(TertiaryButton)<{ menuIsOpen: boolean }>`
    white-space: nowrap;

    @media screen and (max-width: calc(${({ theme }) => theme.mediaQueries.l} - 1px)) {
        display: ${({ menuIsOpen }) => (menuIsOpen ? 'none' : 'inherit')};
        padding: 0 1rem;
        height: 3.2rem;
    }
`;

const StyledPage = styled(Page as FC<React.PropsWithChildren<unknown>>)`
    background: ${({ theme }) => theme.colors.neutral[0]};
    box-shadow: 0 2px 4px 0 ${({ theme }) => transparentize(0.98, theme.colors.neutral[80])},
        0 4px 12px 0 ${({ theme }) => transparentize(0.96, theme.colors.neutral[80])};
    z-index: 1;
    position: relative;
    @media screen and (max-width: calc(${({ theme }) => theme.mediaQueries.l} - 1px)) {
        &:after {
            content: '';
            width: 100%;
            position: absolute;
            left: 0;
            height: 0.4rem;
            top: calc(100% - 0.4rem);
            box-shadow: 0px 2px 4px 0 ${({ theme }) => transparentize(0.98, theme.colors.neutral[80])},
                inset 0px -1px 0px #ebedee;
            z-index: ${({ theme }) => theme.zIndices.overlay + 2};
            mix-blend-mode: multiply;
        }
    }
`;

const StyledNavWrapper = styled(NavWrapper as FC<React.PropsWithChildren<unknown>>)`
    align-items: center;
    background: ${({ theme }) => theme.colors.neutral['0']};
    min-height: 10.3rem;
    display: grid;
    grid-template-columns: 0fr auto 1fr;

    @media screen and (max-width: calc(${({ theme }) => theme.mediaQueries.l} - 1px)) {
        display: flex;
        justify-content: space-between;
        min-height: 7.2rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.xl}) and (min-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        display: flex;
        flex-wrap: wrap;
        margin: 0 2.4rem;
        flex-direction: column-reverse;
        align-items: end;
    }
`;

const HeaderWrapper = styled.header`
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(0);
    transition: transform 200ms ease-in-out;
    position: sticky;
    z-index: ${({ theme }) => theme.zIndices.overlay + 1};

    a {
        color: ${({ theme }) => theme.colors.primary['80']};
        text-decoration: none;
    }

    strong {
        font-family: ${({ theme }) => theme.fontFamily.title};
        font-weight: bold;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        align-items: center;
        padding: 0;
        margin-bottom: 0;

        a {
            &:hover,
            &:focus {
                color: ${({ theme }) => theme.colors.primary['60']};
            }
        }
    }
`;

const LogoWrapper = styled.div`
    max-width: 10rem;
    width: 100%;
    cursor: pointer;
    text-align: center;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        max-width: none;
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.xl}) and (min-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        margin-top: 0.6rem;
    }
`;

const Icons = styled.div<{ open: boolean }>`
    display: flex;
    flex-direction: row;
    transition: color 0.5s ease-in-out;
    align-items: center;
    justify-content: flex-end;
    height: 4rem;
    color: ${({ theme }) => theme.colors.primary['80']};

    ${({ open, theme }) =>
        open &&
        `
            transition: color 0.1s ease-in-out;
            color: ${theme.colors.primary['80']}
            `};

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        position: relative;
        display: flex;
        width: initial;
    }

    > a {
        display: inline-flex;
    }

    > * + * {
        margin-left: 1.6rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.xl}) and (min-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        padding: 0.4rem 0 0.4rem 0.4rem;
        margin-top: 1rem;
    }
`;

const StyledHamburgerMenu = styled(HamburgerMenu)`
    display: unset;
    width: 4.4rem;
    text-align: right;
    margin-left: 0 !important;

    button {
        &:focus {
            outline-width: 0;
            outline-style: dashed;
            outline-color: transparent;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        display: none;
    }
`;

const StyledLogo = styled(Logo)<{ isTop: boolean }>`
    transition: color 0.3s ease-in-out;
    max-width: 13.2rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xs}) {
        max-width: none;
        padding-right: 1.6rem;
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        ${({ isTop }) => isTop && `margin-bottom: 2.4rem;`}
    }
`;

const LogoLinkTerschelling = styled.a`
    display: flex;
    flex-direction: row;
    color: ${({ theme }) => theme.colors.primary[40]} !important;
    display: block;
    position: relative;
    top: 0.2rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        color: ${({ theme }) => theme.colors.neutral[0]} !important;
        width: 30.3rem;
        transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out, background-color 0.5s ease-in-out;
        padding: 0 2.4rem;
        align-items: stretch;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 999;
        display: flex;
        flex-direction: row;
        border-radius: 0 0 6px;
    }
`;

const LogoContainer = styled.div`
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        height: 104px;
        display: flex;
        align-items: center;
        margin-left: 2rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.xl}) and (min-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        height: 64px;
    }
`;

const Flag = styled.img`
    display: none;
    filter: drop-shadow(0 0 4px rgb(0 0 0 / 0.2));
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        position: relative;
        display: inline-block;
        width: 6.4rem;
        max-height: 13.9rem;
    }
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.xl}) and (min-width: ${({ theme }) =>
            theme.mediaQueries.l}) {
        width: 8.8rem;
        max-height: 16rem;
    }
`;
const StyledWebshopButton = styled(Button)`
    height: 3.6rem;
    padding: 0.4rem 0.8rem;
    font-size: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding: 0.4rem 1.6rem;
        box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.colors.neutral[20]}, 0 2px 4px 0 rgba(59, 118, 160, 0.02),
            0 4px 12px 0 rgba(59, 118, 160, 0.04);
        border-radius: ${({ theme }) => theme.radius.button};
        font-family: ${({ theme }) => theme.fontFamily.title};
        font-weight: 800;
    }

    svg {
        margin-right: 0.4rem;
        width: 2rem;
        height: 2rem;
    }
    & :hover {
        color: ${({ theme }) => theme.colors.secondary[60]};
        box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.colors.neutral[30]};
    }
`;
