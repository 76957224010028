// Note: this file is the starting point for the new token system

const colors = {
    blue: {
        0: '#F5F8FA',
        5: '#DFF1FB',
        10: '#C9E7F7',
        20: '#A3D5EE',
        30: '#80C1E1',
        40: '#62AAD1',
        50: '#4F8FC0',
        60: '#3E71A9',
        70: '#0A59A6',
        80: '#223260',
        90: '#141940',
        100: '#070717',
    },
    yellow: {
        0: '#FFFDF3',
        5: '#FFF6DD',
        10: '#FFE9BF',
        20: '#F3D76C',
        30: '#f9be49',
        40: '#f2a71e',
        50: '#E79200',
        60: '#DA8300',
        70: '#CA7300',
        80: '#B66000',
        90: '#A04E00',
        100: '#8A3E00',
    },
    magenta: {
        50: '#FF4087',
        60: '#ff1a6f',
    },
    neutral: {
        0: '#ffffff',
        5: '#fbfcfc',
        10: '#f7f8f9',
        20: '#e6e9eb',
        30: '#cbd0d4',
        40: '#a4acb2',
        50: '#767f87',
        60: '#48515b',
        70: '#212a36',
        80: '#060e1c',
        90: '#00040d',
        100: '#000208',
    },
    positive: {
        0: '#ebffeb',
        5: '#E2FDE3',
        10: '#d9fbdb',
        20: '#b3f0b5',
        30: '#7ade7e',
        40: '#36c43c',
        50: '#00a308',
        60: '#008207',
        70: '#006606',
        80: '#005206',
        90: '#004705',
        100: '#004206',
    },
    warning: {
        0: '#ffe8be',
        5: '#fed790',
        10: '#fec662',
        20: '#f9ae2c',
        30: '#f1990b',
        40: '#e48800',
        50: '#d37800',
        60: '#be6500',
        70: '#a65000',
        80: '#8c3d00',
        90: '#722c00',
        100: '#591e00',
    },
    negative: {
        0: '#fff1f1',
        5: '#FEEBEB',
        10: '#fde6e6',
        20: '#f8cecd',
        30: '#f2a8a7',
        40: '#e87975',
        50: '#dc463e',
        60: '#cc1a0d',
        70: '#b71100',
        80: '#9f1400',
        90: '#831400',
        100: '#661300',
    },
    vvv: '#072A8B',
};

const text = {
    header: colors.blue[80],
    body: colors.blue[90],
    note: colors.neutral[50],
};

const section = {
    dark: colors.blue[70],
    light: colors.neutral[0],
    neutral: colors.blue[0],
};

const cta = {
    primary: {
        enabled: colors.magenta[50],
        hover: colors.magenta[50],
        pressed: colors.magenta[60],
    },
    secondary: {
        enabled: colors.blue[70],
        hover: colors.blue[70],
        pressed: colors.blue[80],
    },
    tertiary: {
        enabled: colors.blue[80],
        hover: colors.blue[80],
        pressed: colors.blue[70],
    },
};

const input = {
    input: colors.blue[80],
    placeholder: colors.neutral[40],
    border: colors.neutral[20],
    hover: colors.neutral[30],
    focus: colors.neutral[70],
};

const rating = colors.yellow[40];

const deal = colors.yellow[40];

const seperator = 'rgba(0,0,0,0)';

const space = {
    0: '0px',
    1: '4px',
    2: '8px',
    3: '12px',
    4: '16px',
    5: '24px',
    6: '32px',
    7: '40px',
    8: '56px',
};

const radius = {
    button: '8px',
    textInput: '8px',
    textArea: '8px',
    tag: '16px',
};

const fontFamilies = {
    heading: 'Open Sans Condensed',
    body: 'Open Sans',
    action: 'Open Sans Condensed',
};

const letterSpacing = {
    normal: '0%',
};

const fontWeights = {
    heading: 'Bold',
    body: {
        regular: 'Regular',
        bold: 'Bold',
    },
    action: 'Bold',
    rating: 'Bold',
    price: 'Bold',
};

const lineHeights = {
    body: '150%',
    tight: '130%',
    none: '100%',
    action: 'none',
};

const typography = {
    desktop: {
        title: {
            large: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '39',
                lineHeight: '40',
                letterSpacing: letterSpacing.normal,
            },
            regular: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '31',
                lineHeight: '32',
                letterSpacing: letterSpacing.normal,
            },
            small: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '20',
                lineHeight: '24',
                letterSpacing: letterSpacing.normal,
            },
            tiny: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '16',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
            micro: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '16',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
        },
        body: {
            huge: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '21',
                lineHeight: '32',
                letterSpacing: letterSpacing.normal,
            },
            large: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '19',
                lineHeight: '28',
                letterSpacing: letterSpacing.normal,
            },
            regular: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '17',
                lineHeight: '28',
                letterSpacing: letterSpacing.normal,
            },
            small: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '16',
                lineHeight: '24',
                letterSpacing: letterSpacing.normal,
            },
            tiny: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '15',
                lineHeight: '16',
                letterSpacing: letterSpacing.normal,
            },
            micro: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '12',
                lineHeight: '16',
                letterSpacing: letterSpacing.normal,
            },
        },
        action: {
            regular: {
                fontFamily: fontFamilies.action,
                fontWeight: fontWeights.action,
                fontSize: '17',
                lineHeight: lineHeights.action,
                letterSpacing: letterSpacing.normal,
            },
            small: {
                fontFamily: fontFamilies.action,
                fontWeight: fontWeights.action,
                fontSize: '15',
                lineHeight: lineHeights.action,
                letterSpacing: letterSpacing.normal,
            },
            tiny: {
                fontFamily: fontFamilies.action,
                fontWeight: fontWeights.action,
                fontSize: '12',
                lineHeight: lineHeights.action,
                letterSpacing: letterSpacing.normal,
            },
        },
        rating: {
            small: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.rating,
                fontSize: '14',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
        },
        deal: {
            small: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.rating,
                fontSize: '14',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
        },
        price: {
            regular: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.price,
                fontSize: '17',
                lineHeight: lineHeights.none,
                letterSpacing: letterSpacing.normal,
            },
            small: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.rating,
                fontSize: '14',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
        },
    },
    mobile: {
        title: {
            large: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '26',
                lineHeight: '28',
                letterSpacing: letterSpacing.normal,
            },
            regular: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '24',
                lineHeight: '28',
                letterSpacing: letterSpacing.normal,
            },
            small: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '19',
                lineHeight: '28',
                letterSpacing: letterSpacing.normal,
            },
            tiny: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '17',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
            micro: {
                fontFamily: fontFamilies.heading,
                fontWeight: fontWeights.heading,
                fontSize: '14',
                lineHeight: '16',
                letterSpacing: letterSpacing.normal,
            },
        },
        body: {
            huge: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '15',
                lineHeight: '28',
                letterSpacing: letterSpacing.normal,
            },
            large: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '15',
                lineHeight: '28',
                letterSpacing: letterSpacing.normal,
            },
            regular: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '15',
                lineHeight: '28',
                letterSpacing: letterSpacing.normal,
            },
            small: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '15',
                lineHeight: '24',
                letterSpacing: letterSpacing.normal,
            },
            tiny: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '12',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
            micro: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.body.regular,
                fontSize: '12',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
        },
        action: {
            regular: {
                fontFamily: fontFamilies.action,
                fontWeight: fontWeights.action,
                fontSize: '15',
                lineHeight: lineHeights.action,
                letterSpacing: letterSpacing.normal,
            },
            small: {
                fontFamily: fontFamilies.action,
                fontWeight: fontWeights.action,
                fontSize: '15',
                lineHeight: lineHeights.action,
                letterSpacing: letterSpacing.normal,
            },
            tiny: {
                fontFamily: fontFamilies.action,
                fontWeight: fontWeights.action,
                fontSize: '12',
                lineHeight: lineHeights.action,
                letterSpacing: letterSpacing.normal,
            },
        },
        rating: {
            small: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.rating,
                fontSize: '14',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
        },
        deal: {
            small: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.rating,
                fontSize: '14',
                lineHeight: '20',
                letterSpacing: letterSpacing.normal,
            },
        },
        price: {
            regular: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.price,
                fontSize: '15',
                lineHeight: lineHeights.none,
                letterSpacing: letterSpacing.normal,
            },
            small: {
                fontFamily: fontFamilies.body,
                fontWeight: fontWeights.rating,
                fontSize: '12',
                lineHeight: lineHeights.none,
                letterSpacing: letterSpacing.normal,
            },
        },
    },
};

const theme = {
    colors,
    text,
    section,
    cta,
    input,
    rating,
    deal,
    seperator,
    space,
    radius,
    fontFamilies,
    letterSpacing,
    fontWeights,
    lineHeights,
    typography,
};

export default theme;
