import { Locale } from '@oberoninternal/travelbase-website/dist/entities/Locale';

const getAgendaPageSlugByLocale = (locale: Locale): string => {
    switch (locale) {
        case 'nl':
            return 'excursies';
        case 'en':
            return 'excursions';
        case 'de':
        default:
            return 'ausfluge';
    }
};

export default getAgendaPageSlugByLocale;
